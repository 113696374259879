import React from "react";

import {IInfiniteListQuery} from "@pg-design/infinite-list";

import {OfferModal} from "../../components/offer_modal/OfferModal";
import {useHomepageOffers} from "../../hooks/use_homepage_offers";
import {IOfferListOffer} from "../types/IOfferListResponse";

export function HomepageOfferListingModal() {
    const homepageOffersQuery = useHomepageOffers();

    return <OfferModal modalName="mainOfferModal" offersQuery={homepageOffersQuery as unknown as IInfiniteListQuery<IOfferListOffer>} />;
}
