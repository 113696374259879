import {pluralize} from "@pg-mono/string-utils";

export enum PropertyHouseType {
    DETACHED = 1,
    SEMI_DETACHED = 2,
    SERIAL = 3,
    WOODEN = 4
}

export function getPropertyHouseType(propertyHouseType: PropertyHouseType) {
    switch (propertyHouseType) {
        case PropertyHouseType.DETACHED:
            return "Dom wolnostojący";
        case PropertyHouseType.SEMI_DETACHED:
            return "Bliźniak";
        case PropertyHouseType.SERIAL:
            return "Dom szeregowy";
        case PropertyHouseType.WOODEN:
            return "Dom z bali";
        default:
            throw new Error("Unknown property house type");
    }
}

export const housePlural = pluralize(["domu", "domów", "domów"]);

export const renderHouseTypes = (houseTypes: Record<string, number>) =>
    Object.entries(houseTypes).map(([type, quantity]) => {
        const intType = parseInt(type, 10);
        if (intType === PropertyHouseType.DETACHED) {
            return ` ${quantity} ${housePlural(quantity)} typu ${getPropertyHouseType(intType).toLowerCase()}`;
        } else if (intType === PropertyHouseType.SEMI_DETACHED) {
            return ` ${quantity} ${housePlural(quantity)} typu ${getPropertyHouseType(intType).toLowerCase()}`;
        } else if (intType === PropertyHouseType.SERIAL) {
            return ` ${quantity} ${housePlural(quantity)} typu ${getPropertyHouseType(intType).toLowerCase()}`;
        } else if (intType === PropertyHouseType.WOODEN) {
            return ` ${quantity} ${housePlural(quantity)} typu ${getPropertyHouseType(intType).toLowerCase()}`;
        }
    });
