import {ElementType, FC, ReactNode} from "react";

import {Container} from "./Container";

interface IProps {
    children: ReactNode;
    className?: string;
    id?: string;
    as?: ElementType;
}

export const WideContent: FC<IProps> = (props) => {
    return (
        <Container id={props.id} className={props.className} as={props.as}>
            {props.children}
        </Container>
    );
};
