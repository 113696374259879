import {css} from "@emotion/react";

export const modalStyles = css`
    .ReactModal__Overlay {
        -webkit-perspective: 600;
        perspective: 600;
        opacity: 0;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1;
        transition: opacity 150ms ease-out;
    }

    .ReactModal__Content {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    .ReactModal__Content--after-open {
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: all 200ms ease-in;
    }

    .ReactModal__Overlay--before-close {
        opacity: 0;
    }

    .ReactModal__Content--before-close {
        -webkit-transform: scale(0);
        transform: scale(0);
        transition: all 200ms ease-in;
    }

    .ReactModal__Body--open,
    .ReactModal__Html--open {
        overflow: hidden;
    }
`;
