import {useSelector} from "react-redux";

import {IInfiniteListQuery} from "@pg-design/infinite-list";

import {IRPStore} from "../../app/rp_reducer";
import {HOMEPAGE_OFFERS_PANEL_LIST_PAGE, HOMEPAGE_OFFERS_PANEL_LIST_PAGE_SIZE} from "../../homepage/actions/fetch_homepage_offer_list";
import {IHomepageOfferListOffer} from "../../homepage/interfaces/IHomepageOfferListOffer";
import {getQueryStatus} from "../../request/get_query_status";

export function useHomepageOffers(): IInfiniteListQuery<IHomepageOfferListOffer> {
    const recommendedOffersList = useSelector((state: IRPStore) => state.homepage.recommendedOfferList);
    const recommendedOffersRequest = useSelector((state: IRPStore) => state.homepage.requestState);

    return {
        ...getQueryStatus(recommendedOffersRequest),
        data: recommendedOffersList,
        count: HOMEPAGE_OFFERS_PANEL_LIST_PAGE_SIZE,
        requestState: recommendedOffersRequest,
        page: HOMEPAGE_OFFERS_PANEL_LIST_PAGE,
        pageSize: HOMEPAGE_OFFERS_PANEL_LIST_PAGE_SIZE
    };
}
