import React from "react";

import {HomepageBlogSection} from "../components/HomepageBlogSection";
import {HomepageCompetitionSection} from "../components/HomepageCompetitionSection";
import {HomepageFeaturesSection} from "../components/HomepageFeaturesSection";
import {HomepageHeader} from "../components/HomepageHeader";
import {HomepageMarketplacePromoSection} from "../components/HomepageMarketplacePromoSection";
import {HomepageRecommendedOffers} from "../components/HomepageRecommendedOffers";
import {HomepageWhyWeSection} from "../components/HomepageWhyWeSection";
import {SchemaHomepage} from "../components/SchemaHomepage";

export const HomepageView = () => {
    return (
        <div>
            <HomepageHeader />
            <HomepageRecommendedOffers />
            <HomepageCompetitionSection />
            <HomepageFeaturesSection />
            <HomepageBlogSection />
            <HomepageMarketplacePromoSection />
            <HomepageWhyWeSection />
            <SchemaHomepage />
        </div>
    );
};
