import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BrandChartIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M4.566 1.576c-.201.026-.422.097-.756.242-.73.317-1.05.521-1.412.898a2.127 2.127 0 0 0-.417.554c-.194.349-.268.536-.343.863-.105.443-.1 1.203.011 1.732.05.237.188.656.268.817.114.223.495.72.7.91.414.388.959.64 1.63.751.319.054 1.01.06 1.326.012.32-.048.993-.306 1.398-.536.197-.111.284-.182.547-.445.273-.273.332-.35.486-.609.322-.547.436-1.04.433-1.884-.002-.4-.009-.504-.052-.682a4.584 4.584 0 0 0-.649-1.37c-.213-.293-.66-.663-1.12-.926-.522-.296-.65-.33-1.375-.339-.286-.004-.59 0-.675.012Zm1.182.263c.095.022.595.206.595.22 0 .02-.381.875-.545 1.223-.107.228-.258.562-.334.744-.078.18-.173.382-.213.45l-.074.121.017-.185c.014-.196.057-1.879.06-2.348v-.282l.193.017c.107.01.242.026.301.04Zm1.502.896c.273.26.356.379.57.786.172.332.312.714.347.958.048.329.017.9-.073 1.35-.052.265-.244.694-.438.988-.164.244-.465.55-.647.656-.396.233-.853.446-1.104.52-.45.13-.65.153-1.05.125-.405-.029-.85-.1-1.07-.17a4.172 4.172 0 0 1-.73-.358c-.27-.188-.813-.827-.903-1.069-.026-.064-.024-.069.1-.119.066-.028.262-.118.43-.2.171-.084.41-.193.534-.243.123-.05.376-.16.561-.246.185-.085.569-.242.853-.348.284-.107.535-.209.56-.23.049-.045.371-.697.532-1.083.067-.156.23-.519.365-.805a65.7 65.7 0 0 0 .363-.782l.116-.263.222.154c.124.083.33.253.462.379Zm-4.16.151c.118.133.372.413.564.621.192.209.512.576.71.818l.444.533.083.092-.751.31c-.486.204-.988.434-1.427.657l-.68.346-.083-.26c-.158-.487-.192-.861-.12-1.394.097-.74.326-1.242.796-1.737.118-.125.225-.23.234-.23.007 0 .111.11.23.244Z" />
        </SvgIcon>
    );
};
