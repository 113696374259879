import {batch} from "react-redux";
import {Dispatch} from "redux";

import {createFavouritesActionTypes} from "@pg-mono/favourites";
import {isEmpty} from "@pg-mono/nodash";
import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../app/rp_reducer";
import {IOfferDetail} from "../../offer/detail/types/IOfferDetail";
import {IPropertyDetail} from "../../property/interfaces/IPropertyDetail";

const FAVOURITE_OFFERS_LIST = "favourite_offers_list/FETCH";
export const fetchFavouriteOffersListTypes = createFavouritesActionTypes(FAVOURITE_OFFERS_LIST);

const FAVOURITE_PROPERTIES_LIST = "favourite_properties_list/FETCH";
export const fetchFavouritePropertiesListTypes = createFavouritesActionTypes(FAVOURITE_PROPERTIES_LIST);

const fetchFavouriteOffersList = (offers: number[]) => {
    if (isEmpty(offers)) {
        return [];
    }

    return Promise.all(
        offers.map((offerId) => {
            const offerDetailApiLink = apiV2Link.offer.detail(Scenario.OFFER_DETAIL, {offerId});

            return getRequest({}, offerDetailApiLink);
        })
    );
};

const fetchFavouritePropertiesList = (properties: number[]) => {
    if (isEmpty(properties)) {
        return [];
    }

    return Promise.all(
        properties.map((propertyId) => {
            const propertyDetailApiLink = apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId});
            return getRequest({}, propertyDetailApiLink);
        })
    );
};

export const fetchMyOffersFavouritesList = () => (dispatch: Dispatch, getState: () => IRPStore) => {
    const {favourites} = getState().favourite;

    return Promise.all([fetchFavouriteOffersList(favourites.offers), fetchFavouritePropertiesList(favourites.properties)]).then(
        ([offers, properties]: [IOfferDetail[], IPropertyDetail[]]) => {
            batch(() => {
                dispatch({type: fetchFavouriteOffersListTypes.set, favourites: offers});
                dispatch({type: fetchFavouritePropertiesListTypes.set, favourites: properties});
            });
        }
    );
};

export const removeFromMyOffersFavouritesList = (favourites: {offers: number[]; properties: number[]}) => async (dispatch: Dispatch) => {
    batch(() => {
        dispatch({type: fetchFavouriteOffersListTypes.remove, favouritesToRemove: favourites.offers});
        dispatch({type: fetchFavouritePropertiesListTypes.remove, favouritesToRemove: favourites.properties});
    });
};
