import {Country} from "../../region/types/Country";

export enum InvestmentOfferTypeSlug {
    HOLIDAY = "apartamenty-wakacyjne",
    INVESTMENT = "apartamenty-inwestycyjne",
    CONDOHOTEL = "condohotel",
    FOR_RENT = "pod-wynajem",
    ABROAD = "za-granica"
}

export enum InvestmentOfferAreaSlug {
    LAKE = "nad-jeziorem",
    SEA = "nad-morzem",
    MOUNTAIN = "w-gorach"
}

export type InvestmentOfferCategory = InvestmentOfferTypeSlug | InvestmentOfferAreaSlug;

export enum InvestmentOfferSubTypeSlug {
    All = "",
    LAKE = "nad-jeziorem",
    SEA = "nad-morzem",
    MOUNTAIN = "w-gorach"
}

export enum InvestmentOfferCitySlug {
    All = "",
    GDANSK = "gdansk",
    KRAKOW = "krakow",
    LODZ = "lodz",
    POZNAN = "poznan",
    WARSZAWA = "warszawa",
    WROCLAW = "wroclaw"
}

export enum InvestmentOfferCountrySlug {
    SPAIN = "hiszpania"
}

export type InvestmentOfferSubCategory = InvestmentOfferSubTypeSlug | InvestmentOfferCitySlug | InvestmentOfferCountrySlug;

export enum InvestmentOfferCityName {
    GDANSK = "Gdańsk", // 46083
    KRAKOW = "Kraków", // 11158
    LODZ = "Łódź", // 16187
    POZNAN = "Poznań", // 30892
    WARSZAWA = "Warszawa", // 8647
    WROCLAW = "Wrocław" // 26955
}

export const investmentOfferCities = [
    {id: 46083, name: "Gdańsk", slug: InvestmentOfferCitySlug.GDANSK, name_declension_where: "w Gdańsku"},
    {id: 11158, name: "Kraków", slug: InvestmentOfferCitySlug.KRAKOW, name_declension_where: "w Krakowie"},
    {id: 16187, name: "Łódź", slug: InvestmentOfferCitySlug.LODZ, name_declension_where: "w Łodzi"},
    {id: 30892, name: "Poznań", slug: InvestmentOfferCitySlug.POZNAN, name_declension_where: "w Poznaniu"},
    {id: 8647, name: "Warszawa", slug: InvestmentOfferCitySlug.WARSZAWA, name_declension_where: "w Warszawie"},
    {id: 26955, name: "Wrocław", slug: InvestmentOfferCitySlug.WROCLAW, name_declension_where: "we Wrocławiu"}
];

export const investmentOfferTypes = [
    {name: "Condohotel", slug: InvestmentOfferTypeSlug.CONDOHOTEL, namePlural: "Condohotele"},
    {name: "Apartament wakacyjny", slug: InvestmentOfferTypeSlug.HOLIDAY, namePlural: "Apartamenty wakacyjne"},
    {name: "Pod wynajem", slug: InvestmentOfferTypeSlug.FOR_RENT, namePlural: "Pod wynajem"}
];

export const abroadInvestmentOfferType = {name: "Za granicą", slug: InvestmentOfferTypeSlug.ABROAD, namePlural: "Za granicą"};

export const allInvestmentOfferTypes = investmentOfferTypes.concat(abroadInvestmentOfferType);
export const investmentOfferHolidayTypes = [
    {name: "Wszędzie", slug: InvestmentOfferSubTypeSlug.All},
    {name: "Nad morzem", slug: InvestmentOfferSubTypeSlug.SEA},
    {name: "W górach", slug: InvestmentOfferSubTypeSlug.MOUNTAIN},
    {name: "Nad jeziorem", slug: InvestmentOfferSubTypeSlug.LAKE}
];

export const investmentOfferCountries = [{country: Country.SPAIN, name: "W Hiszpanii", slug: InvestmentOfferCountrySlug.SPAIN}];
