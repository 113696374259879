import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {Button} from "@pg-design/button";
import {Container} from "@pg-design/grid";
import {m, mb, p, w100, zIndex} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";
import {useThemeNumericBreakpoints} from "@pg-design/themes";

import {COMPETITION_LINK} from "../../constants/external_links";

const competitionLg1x = require("../images/competition_lg_1x.png");
const competitionMd1x = require("../images/competition_md_1x.png");
const competitionSm1x = require("../images/competition_sm_1x.png");

const competitionLg2x = require("../images/competition_lg_2x.png");
const competitionMd2x = require("../images/competition_md_2x.png");
const competitionSm2x = require("../images/competition_sm_2x.png");

export const HomepageCompetitionSection = () => {
    const {sm, lg} = useThemeNumericBreakpoints();

    return (
        <Container as="section" css={content}>
            <div css={picturePositioner}>
                <Picture
                    sources={[
                        {minWidthPX: 0, srcSet: `${competitionSm1x} 1x, ${competitionSm2x} 2x`, width: 950, height: 574},
                        {minWidthPX: sm, srcSet: `${competitionMd1x} 1x, ${competitionMd2x} 2x`, width: 950, height: 574},
                        {minWidthPX: lg, srcSet: `${competitionLg1x} 1x, ${competitionLg2x} 2x`, width: 950, height: 574}
                    ]}
                    alt="konkurs"
                    loading="lazy"
                />
            </div>
            <div css={descriptionWrapper}>
                <div css={[description]}>
                    <Text variant="headline_1" fontFamily="secondary" css={mb(3)}>
                        Wygraj 100 tys. zł na mieszkanie!
                    </Text>
                    <Text variant="body_copy_0" css={mb(4)}>
                        Rozpocznij poszukiwania z nami, kup mieszkanie od dewelopera i zgarnij 100 tysięcy złotych lub inne atrakcyjne nagrody.
                    </Text>
                    <StyledButton href={COMPETITION_LINK} variant="filled_secondary" target="_blank">
                        Dowiedz się więcej
                    </StyledButton>
                </div>
            </div>
        </Container>
    );
};

const content = (theme: Theme) => css`
    ${m(6, "auto")};
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        ${m(8, "auto")};
        flex-direction: row;
        align-items: center;
    }
`;

const descriptionWrapper = (theme: Theme) => css`
    ${w100};
    display: flex;
    justify-content: flex-end;
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 47.6rem;
        min-width: 64.8rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        height: 40.4rem;
        min-width: 84rem;
    }
`;

const description = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    ${p(4, 2)};
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 9.6rem 12.8rem;
    }
`;

const picturePositioner = (theme: Theme) => css`
    ${w100};
    ${zIndex(10)};
    position: relative;
    display: flex;
    justify-content: center;
    bottom: -2.6rem;

    @media (min-width: ${theme.breakpoints.sm}) {
        bottom: unset;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        justify-content: flex-end;
        left: 5rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        left: 3.4rem;
    }
`;

const StyledButton = styled(Button)`
    ${w100};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        width: auto;
    }
`;
