import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {appendQueryString, catch404, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {IPaginatedResponse} from "../../request/IResponse";

const MARKETPLACE_ARCHITECTS_PREFIX = "marketplace_architects/fetch";
export const fetchMarketplaceArchitectsType = createRequestActionTypes(MARKETPLACE_ARCHITECTS_PREFIX);

export interface IMarketplaceArchitect {
    id: number;
    name: string;
    slug: string;
    min_price_m2: string;
}

export interface IMarketplaceArchitectsResponse extends IPaginatedResponse {
    results: IMarketplaceArchitect[];
}

export const fetchMarketplaceArchitects = (ctx: Pick<IFetchContext<IRPRequestMeta>, "meta" | "prevResult">) => (dispatch: Dispatch) => {
    dispatch({type: fetchMarketplaceArchitectsType.start});

    const regionId = ctx.prevResult?.region;
    const params: Record<string, string> = {sort: "min_price_m2"};

    if (regionId) {
        params.region = regionId;
    }

    const marketplaceArchitectsLink = appendQueryString(apiLink.marketplace.architect.list(), params);
    return getRequest(ctx.meta, marketplaceArchitectsLink)
        .then((marketplaceRegions: IMarketplaceArchitectsResponse) => {
            dispatch({
                type: fetchMarketplaceArchitectsType.success,
                result: marketplaceRegions.results
            });

            return marketplaceRegions;
        })
        .catch(
            catch404(async (err) => {
                notifyBugsnag(err, "marketplace architects 404", err.response ?? {});

                dispatch({
                    type: fetchMarketplaceArchitectsType.success,
                    result: []
                });

                return [];
            })
        );
};
