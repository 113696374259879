import React from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Col, Container, Row} from "@pg-design/grid";
import {flexAlignCenter, flexJustifyCenter, zIndex} from "@pg-design/helpers-css";
import {ChevronUpIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {useIsMobile} from "@pg-mono/hooks";

import {animationOpacityMoveX, animationOpacityScale} from "../../app/styles/animations";
import {Animated} from "../../atoms/Animated";
import {useHomepageBlogCategories} from "../hooks/use_homepage_blog_categories";
import {HomepageBlogCategory} from "./HomepageBlogCategory";

export const HomepageBlogSection = () => {
    const theme = useTheme();
    const isMobile = useIsMobile();
    const homepageBlogCategories = useHomepageBlogCategories();

    const rootMargin = isMobile ? "" : "-30% 0%";
    const iconAnimation = animationOpacityScale(0.4, 0.5, true, rootMargin);
    const leftBoxAnimation = animationOpacityMoveX(0.4, "-45vw", true, rootMargin);
    const rightBoxAnimation = animationOpacityMoveX(0.4, "45vw", true, rootMargin);

    return (
        <Container as="section" fluid css={[homepageBlogSection, flexAlignCenter]}>
            <Row css={zIndex(1)}>
                <Headline align="center" as="h2" fontFamily="secondary" variant={isMobile ? "headline_2" : "headline_1"}>
                    Przeprowadzimy Cię przez zawiły proces poszukiwania mieszkania na każdym etapie
                </Headline>
            </Row>
            <Row css={categories}>
                {homepageBlogCategories.map((homepageBlogCategory, index) => {
                    return (
                        <Col key={index} css={flexJustifyCenter} md={6} sm={4}>
                            <Animated
                                animationRef={index > 0 ? rightBoxAnimation.ref : leftBoxAnimation.ref}
                                style={index > 0 ? rightBoxAnimation.style : leftBoxAnimation.style}
                            >
                                <HomepageBlogCategory {...homepageBlogCategory} />
                            </Animated>
                        </Col>
                    );
                })}
            </Row>

            <div css={iconPositioner}>
                <Animated animationRef={iconAnimation.ref} style={iconAnimation.style}>
                    <ChevronUpIcon fill={theme.colors.primary} />
                </Animated>
            </div>
        </Container>
    );
};

const homepageBlogSection = (theme: Theme) => css`
    background-color: ${theme.colors.success};
    padding: 4rem 1.6rem 3.2rem;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 8rem 0 3.2rem;
    }
`;

const Headline = styled(Text)`
    margin-bottom: 4.2rem;
    z-index: 1;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        max-width: 89rem;
        margin-bottom: 6.8rem;
    }
`;

const categories = (theme: Theme) => css`
    z-index: 1;

    @media (max-width: ${theme.breakpoints.md}) {
        row-gap: 2.4rem;
    }
`;

const iconPositioner = (theme: Theme) => css`
    position: absolute;
    top: 19rem;
    z-index: 0;

    svg {
        transform: scale(36);
    }

    @media (min-width: ${theme.breakpoints.xs}) {
        top: 14rem;

        svg {
            transform: scale(44);
        }
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        top: 24rem;

        svg {
            transform: scale(60);
        }
    }
`;
