import {useTranslation} from "@pg-design/i18n";
export const regionDefaultOptionValue = 8647;
const regionDefaultOption = {value: 8647, label: "Warszawa", slug: "warszawa"};

const regionOptions = [
    regionDefaultOption,
    {value: 11158, label: "Kraków", slug: "krakow"},
    {value: 26955, label: "Wrocław", slug: "wroclaw"},
    {value: 30892, label: "Poznań", slug: "poznan"},
    {value: 46083, label: "Gdańsk", slug: "gdansk"},
    {value: 16187, label: "Łódź", slug: "lodz"},
    {value: 36190, label: "Kielce", slug: "kielce"},
    {value: 13220, label: "Lublin", slug: "lublin"},
    {value: 22550, label: "Bydgoszcz", slug: "bydgoszcz"}
];

export function useHomepageRegionOptions() {
    //  Had to translate this manually because of this issue - https://app.clickup.com/t/862kar2zn
    const plLabels = regionOptions.map((option) => option.label);
    const translatedLabels = useTranslation(plLabels);

    if (!translatedLabels) {
        return null;
    }

    const translatedOptions = regionOptions.map((regionOption, i) => ({
        ...regionOption,
        label: translatedLabels[i]
    }));

    return {
        regionOptions: translatedOptions,
        regionDefaultOption: translatedOptions[0]
    };
}
