import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, flexAbsoluteCenter, mb, ml, mt, p, ph, pr, w100} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {IOption, Select} from "@pg-design/select";
import {Tabs} from "@pg-design/tabs";
import {Text} from "@pg-design/text";
import {useIsMounted} from "@pg-mono/hooks";
import {RequestState} from "@pg-mono/request-state";

import {IRPStore} from "../../../app/rp_reducer";
import {slickSlider} from "../../../app/styles/slick_slider";
import {tabsWrapper} from "../../../atoms/TabsWrapper";
import {EmptyList} from "../../../offer/atoms/EmptyList";
import {OfferBoxWithOfferDetailsAction} from "../../../offer/atoms/OfferBoxWithOfferDetailsAction";
import {HomepageOfferListingModal} from "../../../offer/list/components/HomepageOfferListingModal";
import {regionDefaultOption} from "../../constants/offer_list";
import {slickSliderSettings} from "../../constants/slick_slider_settings";
import {useHomepageRegionOptions} from "../../hooks/use_homepage_region_options";
import {useLastOfferBoxSize} from "../../hooks/use_last_offer_box_size";
import {MoreOffersBox} from "./MoreOffersBox";

export const LazySlider = React.lazy(() => import("@pg-mono/slick-slider"));

export interface IRegionOption extends IOption {
    slug: string;
}

interface IProps {
    moreOffersText?: string;
    getMoreOffersLink?: (regionValue: IRegionOption, sortValue: string) => string;
    onChange: (regionValue: IRegionOption, sortValue: string) => void;
    sortOptions: {title: React.ReactNode; value: string}[];
    sortInitialValue: string;
    title: string;
}

export const OffersPanel = (props: IProps) => {
    const isMounted = useIsMounted();

    const [regionValue, setRegionValue] = useState<IRegionOption>(regionDefaultOption);
    const [sortValue, setOfferFilterTypeValue] = useState(props.sortInitialValue);
    const [activeSlide, setActiveSlide] = useState(0);
    const lastOfferRef = useRef<HTMLDivElement | null>(null);
    // We need this dimensions in order to set dimensions of the last slick slider item - MoreOffersBox
    // This item is not a part of an offers list, so it is not able (because od slick slider implementation) to set proper dimensions
    const slickLastOfferBoundingRect = useLastOfferBoxSize(lastOfferRef);

    const recommendedOfferList = useSelector((state: IRPStore) => state.homepage.recommendedOfferList);
    const recommendedOfferListRequest = useSelector((state: IRPStore) => state.homepage.requestState);

    const homepageOptions = useHomepageRegionOptions();

    useEffect(() => {
        if (homepageOptions) {
            const currentTranslatedOption = homepageOptions.regionOptions.find((option) => option.value === regionValue.value);

            setRegionValue(currentTranslatedOption ? currentTranslatedOption : regionValue);
        }
    }, [homepageOptions?.regionDefaultOption.label]);

    const onRegionValueChange = (value: IRegionOption) => {
        setRegionValue(value);

        props.onChange(value, sortValue);
    };

    const onOfferTypeValueChange = (value: string) => {
        setOfferFilterTypeValue(value);

        props.onChange(regionValue, value);
    };

    const loader = (
        <div css={loaderWrapper}>
            <Loader size="lg" />
        </div>
    );

    const showLoader = recommendedOfferListRequest === RequestState.None || recommendedOfferListRequest === RequestState.Waiting;

    const showMoreOffersLink = props.getMoreOffersLink?.(regionValue, sortValue) || "";
    const showMoreOffers = !!props.moreOffersText && !!props.getMoreOffersLink;

    return (
        <div css={offersPanel}>
            <div css={header}>
                <Text variant="headline_2" strong>
                    {props.title}
                </Text>

                <div css={filters}>
                    <div css={tabsWrapper}>
                        <Tabs
                            activeColor="gray"
                            fullWidth
                            onChange={onOfferTypeValueChange}
                            color="gray"
                            tabs={props.sortOptions}
                            value={sortValue}
                            variant="contained"
                        />
                    </div>

                    <div css={regions} id="offers-panel-region">
                        {homepageOptions && (
                            <Select
                                instanceId="region-select"
                                options={homepageOptions.regionOptions}
                                value={regionValue}
                                onChange={(value) => onRegionValueChange(value as IRegionOption)}
                            />
                        )}
                    </div>
                </div>
            </div>

            <HomepageOfferListingModal />

            {!isMounted && showLoader && loader}

            {isMounted && (
                <React.Suspense fallback={loader}>
                    {showLoader ? (
                        loader
                    ) : (
                        <>
                            {recommendedOfferList.length === 0 ? (
                                <div css={emptyListWrap}>
                                    <EmptyList />
                                </div>
                            ) : (
                                <SlickWrapper
                                    isOnFirstSlide={activeSlide === 0}
                                    isOnLastSlide={activeSlide === recommendedOfferList.length + Number(showMoreOffers) - 1}
                                >
                                    <LazySlider {...slickSliderSettings} beforeChange={(prev: number, next: number) => setActiveSlide(next)}>
                                        {recommendedOfferList.map((offer, index) => {
                                            const isLast = index + 1 === recommendedOfferList.length;
                                            return (
                                                <div key={offer.id} css={offerBoxWrapper} ref={isLast ? lastOfferRef : undefined}>
                                                    <OfferBoxWithOfferDetailsAction offer={offer} mainPage />
                                                </div>
                                            );
                                        })}

                                        {showMoreOffers && (
                                            <div css={offerBoxWrapper}>
                                                <div style={{height: slickLastOfferBoundingRect?.height}}>
                                                    <MoreOffersBox link={showMoreOffersLink} text={props.moreOffersText as string} />
                                                </div>
                                            </div>
                                        )}
                                    </LazySlider>
                                </SlickWrapper>
                            )}
                        </>
                    )}
                </React.Suspense>
            )}
        </div>
    );
};

const offersPanel = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        padding: 0;
    }
`;

const loaderWrapper = css`
    ${flexAbsoluteCenter};
    ${w100};
    height: 50rem;
`;

const regions = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.md}) {
        width: 16.8rem;
        min-width: 16.8rem;
        ${ml(3)};
    }

    @media (min-width: ${theme.breakpoints.xl}) {
        min-width: 36rem;
    }
`;

const header = (theme: Theme) => css`
    ${mb(2)};
    ${ph(2)};

    @media (min-width: ${theme.breakpoints.sm}) {
        padding-right: 0;
        padding-left: 0;
        ${mb()};
    }

    @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 0;
    }
`;

const filters = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    ${mt(3)};
    ${mb(2)};
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: row;
        ${mt(4)};
    }
`;

export const offerBoxWrapper = (theme: Theme) => css`
    max-width: 302px;

    @media (min-width: ${theme.breakpoints.xs}) {
        min-width: 360px;
        max-width: 384px;
        ${pr(3)};
    }
`;

const SlickWrapper = styled.div<{isOnFirstSlide: boolean; isOnLastSlide: boolean}>`
    ${slickSlider};
    margin: 0 -${calculateRemSize(1)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        padding: 0;
    }

    .slick-list {
        @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
            padding-left: ${(props) =>
                props.isOnLastSlide ? "calc(100% - 302px - 16px)" : props.isOnFirstSlide ? "1.6rem" : "calc((100% - 302px - 8px) / 2)"};
        }

        @media (min-width: ${(props) => props.theme.breakpoints.xs}) and (max-width: ${(props) => props.theme.breakpoints.md}) {
            padding-left: ${(props) =>
                props.isOnLastSlide ? "calc(100% - 360px - 16px)" : props.isOnFirstSlide ? "1.6rem" : "calc((100% - 360px - 8px) / 2)"};
        }

        @media (min-width: ${(props) => props.theme.breakpoints.md}) {
            margin-right: -2.4rem;

            &:after {
                content: "";
                width: 2.4rem;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: ${(props) => props.theme.colors.gray["100"]};
            }
        }
    }

    .slick-track {
        margin: 1rem 0;
        display: flex;

        @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
            column-gap: 1.6rem;
        }

        @media (min-width: ${(props) => props.theme.breakpoints.xs}) {
            margin: 2rem 0 4rem 0;
        }
    }

    .slick-prev,
    .slick-next {
        display: none !important;
        @media (min-width: ${(props) => props.theme.breakpoints.md}) {
            display: block !important;
        }
        &::before {
            display: none;

            @media (min-width: ${(props) => props.theme.breakpoints.md}) {
                display: block;
            }
        }
    }

    .slick-disabled {
        display: none !important;
    }
`;

const emptyListWrap = (theme: Theme) => css`
    ${p(4, 2, 0, 2)};

    @media (min-width: ${theme.breakpoints.sm}) {
        ${p(4, 0, 0, 0)}
    }
`;
