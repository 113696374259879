import React from "react";
import {css, Theme} from "@emotion/react";

import {flexAlignCenter, mb, w50, w100} from "@pg-design/helpers-css";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";
import {useThemeNumericBreakpoints} from "@pg-design/themes";
import {useIsMobile} from "@pg-mono/hooks";

import {YEARS_OF_EXPERIENCE} from "../../app/constants/company";
import {animationOpacityMoveX} from "../../app/styles/animations";
import {Animated} from "../../atoms/Animated";

const familyLg1x = require("../images/family_lg_1x.png");
const familyMd1x = require("../images/family_md_1x.png");
const familySm1x = require("../images/family_sm_1x.png");

const familyLg2x = require("../images/family_lg_2x.png");
const familyMd2x = require("../images/family_md_2x.png");
const familySm2x = require("../images/family_sm_2x.png");

export const HomepageWhyWeSection = () => {
    const {xs, lg} = useThemeNumericBreakpoints();
    const isMobile = useIsMobile();

    const rootMargin = isMobile ? "" : "-30% 0%";
    const leftBoxAnimation = animationOpacityMoveX(0.4, "-45vw", true, rootMargin);
    const rightBoxAnimation = animationOpacityMoveX(0.4, "45vw", true, rootMargin);

    return (
        <section css={homepageWhyWeSection}>
            <Text align="center" as="h2" fontFamily="secondary" variant="headline_1">
                Dlaczego warto nam zaufać?
            </Text>

            <div css={[content]}>
                <div css={descriptionWrapper}>
                    <Animated animationRef={leftBoxAnimation.ref} style={leftBoxAnimation.style}>
                        <div css={[description]}>
                            <Text variant="body_copy_0" css={mb(3)}>
                                <strong>Od {YEARS_OF_EXPERIENCE} lat towarzyszymy naszym użytkownikom w poszukiwaniu nowych mieszkań.</strong>
                            </Text>
                            <Text variant="body_copy_0">
                                Poszukiwanie mieszkania może być ekscytujące, a nie irytujące. <strong>Pomogliśmy już ponad milionowi osób</strong> znaleźć
                                swoje wymarzone M. Dzięki nam podejmiesz dobrą decyzję i przeprowadzisz się do wymarzonego mieszkania.
                            </Text>
                        </div>
                    </Animated>
                </div>
                <div css={picturePositioner}>
                    <Animated animationRef={rightBoxAnimation.ref} style={rightBoxAnimation.style}>
                        <Picture
                            sources={[
                                {minWidthPX: 0, srcSet: `${familySm1x} 1x, ${familySm2x} 2x`, width: 950, height: 574},
                                {minWidthPX: xs, srcSet: `${familyMd1x} 1x, ${familyMd2x} 2x`, width: 950, height: 574},
                                {minWidthPX: lg, srcSet: `${familyLg1x} 1x, ${familyLg2x} 2x`, width: 950, height: 574}
                            ]}
                            alt="family"
                            loading="lazy"
                        />
                    </Animated>
                </div>
            </div>
        </section>
    );
};

const homepageWhyWeSection = (theme: Theme) => css`
    padding: 2.4rem 1.6rem 50%;
    flex-direction: column;
    background-color: #fff;
    min-height: 69.6rem;

    @media (min-width: ${theme.breakpoints.md}) {
        padding: 4.8rem 0 10.4rem;
        ${flexAlignCenter};
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        padding: 4.8rem 0 10.4rem;
        ${flexAlignCenter};
        min-height: 96.4rem;
    }
`;

const content = (theme: Theme) => css`
    margin-top: 2.4rem;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        margin-top: 5.6rem;
        padding: 0 12rem;
        flex-direction: row;
        min-height: 49.3rem;
        overflow: hidden;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        margin-top: 10.6rem;
        padding: 0 16rem;
        flex-direction: row;
        min-height: 66rem;
        overflow: hidden;
    }
`;

const descriptionWrapper = (theme: Theme) => css`
    ${w100};
    display: flex;
    justify-content: flex-end;
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        ${w50};
    }
`;

const description = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    padding: 3.2rem 1.6rem 9.5rem;
    width: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        min-height: 49.3rem;
        min-width: 65rem;
        max-width: 65rem;
        padding: 4.8rem 27.4rem 13.7rem 7rem;
        position: absolute;
        right: -10.6rem;
        ${w100};
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        min-height: 66rem;
        min-width: 87rem;
        max-width: 87rem;
        padding: 7rem 36.6rem 28.5rem 9rem;
    }
`;

const picturePositioner = (theme: Theme) => css`
    position: absolute;
    top: calc(100% - 6rem);
    display: flex;
    justify-content: center;
    ${w100};
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md}) {
        top: 2.4rem;
        left: calc(50% - 12.4rem);
        width: auto;
        overflow: unset;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        top: 2.4rem;
        left: calc(50% - 20rem);
        width: auto;
        overflow: unset;
    }
`;
