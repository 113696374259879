import {OfferDisplayType} from "../../helpers/OfferDisplayType";
import {SpecialListingType} from "../types/SpecialListingType";

export const AVAILABLE_DISTANCE_VALUES = [0, 3, 2, 5, 8, 10, 12, 15, 23, 30];
export const SEARCH_DISTANCE_VALUES = [0, 2, 5, 10];
export const DEFAULT_DISTANCE = 0;
// constant below overrides DEFAULT_DISTANCE, it needs to be a new constant, to avoid hydration issues
export const DEFAULT_DISTANCE_ON_BROWSER = 5;

export const DEFAULT_DISTANCE_FOR_REGIONS_TAB = 0;
export const DEFAULT_DISTANCE_FOR_OFFERS_TAB = 3;
export const DEFAULT_DISTANCE_FOR_PLACES_TAB = 5;
export const OFFER_LIST_PAGE_SIZE = 24;
export const DEFAULT_DISTANCE_RELATED_OFFERS = 3;
export const SPECIAL_LISTING = [SpecialListingType.CHEAP, SpecialListingType.READY, SpecialListingType.LUXURY];
export const MAX_SOLD_OFFERS_PAGE = 2; // we show only limited number of pages of sold offers

/*
 * Privileged offers are special type of offers which will be pulled to the top of the listing.
 * Privileged offers are displayed only when the listing is loaded for the first time.
 * Any filters/sort change will remove privileged offers.
 */
export const PRIVILEGE_OFFER_QUERY_PARAM_NAME = "pid";

// Additional parameters that describe what offer-list should be
export const OFFER_LIST_CONSTRAINTS = {
    limited_presentation: false,
    display_type: OfferDisplayType.FOR_SALE,
    for_sale: true,
    show_on_listing: true
};
