import {useEffect, useState} from "react";

import {useWeglotContext} from "./use_weglot_context";

export function useTranslation(texts: string[]) {
    const {weglot, translatePhrase} = useWeglotContext();

    const [items, setItems] = useState<string[] | null>(null);

    useEffect(() => {
        if (weglot) {
            const translate = () => {
                const translationPromises = texts.map((text) => translatePhrase(text));

                Promise.all(translationPromises).then((translations) => {
                    setItems(translations);
                });
            };

            translate();

            weglot.on("languageChanged", () => {
                translate();
            });
        }
    }, [weglot]);

    return items;
}
