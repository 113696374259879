import React from "react";
import {useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {backgroundColor, flexAbsoluteCenter, flexDirection, mb, mt, pb, pt} from "@pg-design/helpers-css";
import {BrandWallPaintRollerIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {rpAppLink} from "@pg-mono/rp-routes";

import {useResponsiveLinkTarget} from "../../hooks/use_responsive_link_target";

export const HomepageMarketplacePromoSection = () => {
    const theme = useTheme();
    const target = useResponsiveLinkTarget();

    return (
        <section css={[flexAbsoluteCenter, flexDirection("column"), backgroundColor("#fff"), pt(8), pb(3)]}>
            <BrandWallPaintRollerIcon size="16" wrapperSize="16" wrapperColor={theme.colors.primary} />
            <Text as="h3" variant="headline_3" align="center" mb="1.6rem" css={[mt(4)]}>
                Nasi użytkownicy urządzają mieszkania z gwarancją ceny i terminu!
            </Text>
            <Text variant="headline_6" css={[mb(3)]}>
                Sprawdź oferty naszych partnerów
            </Text>
            <Button href={rpAppLink.architect.list()} variant="filled_primary" target={target}>
                zobacz wykończenie pod klucz
            </Button>
        </section>
    );
};
