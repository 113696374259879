import {IOption} from "@pg-design/select";
import {rpAppLink} from "@pg-mono/rp-routes";

import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {buildFriendlyPromotionSlug} from "../../promotion/helpers/build_friendly_promotion_slug";
import {SortValue} from "../constants/offer_list";

export const getMoreOffersLink = (regionValue: IOption & {slug: string}, sortValue: string) => {
    if (sortValue === SortValue.PROMOTED) {
        return rpAppLink.promotions.listFriendly({friendlySlug: buildFriendlyPromotionSlug({region: regionValue.slug})});
    }

    if (sortValue === SortValue.RATING) {
        return rpAppLink.ranking.base();
    }

    return rpAppLink.offer.listFriendly({friendlySlug: offerUrlBuilder({region: regionValue.slug}).friendlySlug});
};
