import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

type IAplaVariant = "dark" | "light" | "white";

interface IProps {
    applyOn: React.ReactNode;
    as?: React.ElementType;
    className?: string;
    overflow?: "hidden" | "scroll";
    variant?: IAplaVariant;
}

export function Apla<T extends {}>(props: React.PropsWithChildren<IProps & T>) {
    const {as = "div", overflow, variant, className, ...restProps} = props;

    return (
        <AplaBase as={as} variant={variant} className={className} {...restProps}>
            {props.applyOn}
            <Content overflow={overflow}>{props.children}</Content>
        </AplaBase>
    );
}

const variantToGradient = {
    dark: css`
        background: linear-gradient(360deg, #23232d 0%, rgba(255, 255, 255, 0) 100%);
    `,
    light: css`
        background: linear-gradient(360deg, #f9f9f9 0%, rgba(249, 249, 249, 0) 100%);
    `,
    white: css`
        background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    `
};

export const aplaCSS = (variant: IAplaVariant = "dark") => css`
    position: relative;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        ${variantToGradient[variant]};
        height: 100%;
        width: 100%;
    }
`;

const AplaBase = styled.div<{variant?: IAplaVariant}>`
    ${({variant}) => aplaCSS(variant)};
    display: inline-block;
`;

const Content = styled.div<{overflow?: "hidden" | "scroll"}>`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;

    ${({overflow}) => css`
        overflow: ${overflow};
    `}
`;
