import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IRegionListRegion} from "../../search/actions/fetch_search_regions_action";

interface IGetRegionDetailListPayload {
    regionIds: number[];
}

export const getRegionDetailListApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getRegionDetailList: build.query<IRegionListRegion[], IGetRegionDetailListPayload>({
            queryFn: async (queryParams, _api, _extraOptions, fetchWithBQ) => {
                const {regionIds} = queryParams;

                const regionDetailListRequests = regionIds.map((regionId) => fetchWithBQ(apiV2Link.region.detail(Scenario.REGION_DETAIL, {regionId})));

                const regionDetailListResponse = (await Promise.all(regionDetailListRequests)) as {data: IRegionListRegion; error?: FetchBaseQueryError}[];

                const regionDetailListErrors = regionDetailListResponse.filter((response) => response.error);

                if (regionDetailListErrors.length > 1 && regionDetailListErrors[0].error) {
                    return {
                        error: regionDetailListErrors[0].error
                    };
                }

                return {
                    data: regionDetailListResponse.map((response) => response.data)
                };
            }
        })
    })
});

export const {useGetRegionDetailListQuery} = getRegionDetailListApi;
