import React from "react";
import {ChangeEvent, forwardRef, MutableRefObject, useEffect, useRef} from "react";
import {css, Interpolation, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {borderRadius, flex, pl, pr} from "@pg-design/helpers-css";
import {MapMarkerOutlineIcon} from "@pg-design/icons";
import {FieldMessage, IInputCoreProps, InputCore, InputWrapper} from "@pg-design/inputs";
import {Pill} from "@pg-design/pill";
import {Text} from "@pg-design/text";
import {IFormFieldProps} from "@pg-mono/form";

import {SearchDropdownArrow} from "../atoms/SearchDropdownArrow";
import {ISearchInputValue} from "../ISearchInputValue";

interface IOwnProps {
    autoFocus?: boolean;
    openDropdown: () => void;
    closeDropdown: () => void;
    activeItemLabel?: string;
    placeholder: string;
    regionPillLabel: string | null;
    isDropdownOpen: boolean;
    inputIcon?: JSX.Element;
    inputWrapperCss?: Interpolation<Theme>;
    inputContainerCss?: Interpolation<Theme>;
    iconWrapperCss?: Interpolation<Theme>;
    inputCss?: Interpolation<Theme>;
    regionPillWrapperCss?: Interpolation<Theme>;
    readOnly?: boolean;
    hideArrowIcon?: boolean;
    onRemoveAllRegions: () => void;
    onInputMount?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
    isInputWithDistanceFilter?: boolean;
    onInputClick?: () => void;
    inputDisabled?: boolean;
    showArrowOnMobile?: boolean;
    isTravelTimeTabActive?: boolean;
}

export interface ISearchAutocompleteInputProps extends IOwnProps, IFormFieldProps<string, ISearchInputValue> {}

export const SearchAutocompleteInput = (props: ISearchAutocompleteInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        props.onInputMount && props.onInputMount(inputRef);
    }, [inputRef]);

    /**
     * Callback
     */

    const onMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
        // Safari unselects selected onFocus input value. This solves the problem.
        e.preventDefault();
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newInputValue = {
            ...props.value,
            label: (e.target as HTMLInputElement).value
        };

        props.onChange(props.name, newInputValue);
        props.openDropdown(); // dropdown should be opened after every change
    };

    const onDivClick = () => {
        if (props.onInputClick) {
            props.onInputClick();
        } else {
            inputRef.current && inputRef.current.focus();
            props.openDropdown();
        }
    };
    const onIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        inputRef.current && inputRef.current.focus();
        props.isDropdownOpen ? props.closeDropdown() : props.openDropdown();
    };

    /**
     * Render
     */

    return (
        <div onClick={onDivClick} css={[wrapperStyle, props.inputWrapperCss]}>
            <InputContainerStyle hasError={Boolean(props.error)} css={[props.inputContainerCss]}>
                <div css={[iconWrapper, props.iconWrapperCss]}>{props.inputIcon ?? <MapMarkerOutlineIcon size="2.4" />}</div>

                {props.regionPillLabel && !props.isTravelTimeTabActive && (
                    <div css={[regionPillWrapper, props.regionPillWrapperCss]}>
                        <Pill onCloseClick={props.onRemoveAllRegions} size="small">
                            <Text variant="info_txt_1" noWrap css={pillLabelStyles}>
                                {props.regionPillLabel}
                            </Text>
                        </Pill>
                    </div>
                )}
                <SearchAutocompleteInputCore
                    ref={inputRef}
                    autoComplete="off"
                    name={props.name}
                    value={props.activeItemLabel || props.value.label || ""}
                    placeholder={props.placeholder}
                    onChange={onChange}
                    onMouseUp={onMouseUp}
                    readOnly={props.readOnly}
                    autoFocus={props.autoFocus}
                    hasPill={!!props.regionPillLabel}
                    hasDistanceFilter={!!props.isInputWithDistanceFilter}
                    disabled={props.inputDisabled}
                    isTravelTimeTabActive={props.isTravelTimeTabActive}
                    css={props.inputCss}
                />

                {!props.hideArrowIcon && (
                    <ArrowWrapper onClick={onIconClick} showOnMobile={props.showArrowOnMobile}>
                        <SearchDropdownArrow isDown={props.isDropdownOpen} />
                    </ArrowWrapper>
                )}
            </InputContainerStyle>
            {props.error ? <FieldMessage fieldState="error">{props.error}</FieldMessage> : null}
        </div>
    );
};

type ISearchAutocompleteInputCore = IInputCoreProps & {
    hasPill: boolean;
    hasDistanceFilter: boolean;
    isTravelTimeTabActive?: boolean;
};
export const SearchAutocompleteInputCore = forwardRef<HTMLInputElement, ISearchAutocompleteInputCore>((props, forwardedRef) => {
    return (
        <StyledInputWrapper className={props.className} hasPill={props.hasPill} hasDistanceFilter={!!props.hasDistanceFilter}>
            <InputCore
                testid="search-autocomplete"
                ref={forwardedRef}
                autoComplete="off"
                name={props.name}
                value={props.value}
                placeholder={props.isTravelTimeTabActive ? "Wpisz dokładny adres" : props.placeholder}
                onChange={props.onChange}
                onMouseUp={props.onMouseUp}
                readOnly={props.readOnly}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                css={textOverflowStyle}
            />
        </StyledInputWrapper>
    );
});

const regionPillWrapper = (theme: Theme) => css`
    position: relative;
    display: none;
    ${pr(2)};

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
    }
`;

const pillLabelStyles = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
`;

const wrapperStyle = css`
    position: relative;
    z-index: 1001;
    width: 100%;
`;

const InputContainerStyle = styled.div<{hasError?: boolean}>`
    ${flex("center", "flex-start")};
    background-color: #fff;
    border: 1px solid ${({theme, hasError}) => (hasError ? theme.colors.danger : theme.colors.gray["600"])};
    height: 50px;
    ${borderRadius()};
`;

export const iconWrapper = css`
    flex: 0 0 auto;
    cursor: text;
    z-index: 1;
    height: 100%;
    width: 4.8rem;
    ${pl(2)};
    ${pr(1)};
    display: flex;
    justify-content: center;
    align-items: center;
`;

interface ArrowWrapperProps {
    showOnMobile?: boolean;
}

const arrowWrapperStyle = css`
    position: absolute;
    display: block;
    top: 1.5rem;
    right: 2.5rem;
    cursor: pointer;
    z-index: 1;
`;

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
    display: none;
    ${(props) =>
        props.showOnMobile &&
        css`
            ${arrowWrapperStyle};
            right: 1.6rem;
        `};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${arrowWrapperStyle};
    }
`;

interface IStyledInputWrapper {
    hasPill: boolean;
    hasDistanceFilter: boolean;
}
export const StyledInputWrapper = styled(InputWrapper)<IStyledInputWrapper>`
    flex-basis: 100%;
    width: 100%;
    ${pr(2)};
    ${pl(0)};
    border: none;

    ${({hasDistanceFilter}) =>
        hasDistanceFilter &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-bottom: 0;
            width: 100%;
            z-index: initial;
        `};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &::placeholder {
        color: ${(props) => props.theme.colors.gray[700]};
    }

    &:focus {
        outline: none;
    }

    &:hover {
        box-shadow: none;
    }

    & > div {
        max-width: 80%;
    }
`;

const textOverflowStyle = css`
    text-overflow: ellipsis;
`;
