import {isEmpty} from "@pg-mono/nodash";

import {ISelectedRegion} from "../../offer/list/actions/offer_list_selected_data_actions";
import {SearchTab} from "../actions/fetch_search_all_action";
import {fetchSearchRegionById} from "../actions/fetch_search_region";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const prepareSearchValue = async <T>(query: Record<string, string | string[]>, regions: ISelectedRegion[]) => {
    if (!isEmpty(regions)) {
        return {
            search: {
                label: "",
                regions: [regions[0]],
                tabType: SearchTab.Regions
            }
        };
    }

    if (query.lat && query.lng) {
        return {
            search: {
                label: query.s || "",
                place: {
                    description: query.s
                },
                location: {
                    lat: query.lat,
                    lng: query.lng
                },
                tabType: SearchTab.Places
            }
        };
    }
    return null;
};

export const fetchSearchRegions = async (region: string | string[]) => {
    const regionsToFetch = Array.isArray(region) ? region : [region];

    return Promise.all(regionsToFetch.map(async (regionId) => await fetchSearchRegionById(regionId))).then((regions) => {
        return {
            search: {
                label: "",
                tabType: SearchTab.Regions,
                regions
            }
        };
    });
};
