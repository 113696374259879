import {combineReducers} from "redux";

import {createFormActions, createFormActionTypes, reduceFormValues} from "@pg-mono/form";

import {fromJsonValues} from "../../../form/translate/from_json_values";
import {searchFormFields} from "../../../search/constants/search_form_constants";

const search_actions = "notifications_subscription_modal/SEARCH_FORM";
export const searchFormTypes = createFormActionTypes(search_actions);
export interface INotificationsSubscriptionModalStore {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValues: Record<keyof typeof searchFormFields, any>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer: Record<keyof INotificationsSubscriptionModalStore, any> = {
    formValues: reduceFormValues(searchFormTypes, fromJsonValues(searchFormFields, {}))
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const searchFormActions = createFormActions<Record<keyof typeof searchFormFields, any>>(searchFormTypes);

export const notificationsSubscriptionModalReducer = combineReducers(reducer);
