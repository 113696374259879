import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";
import {bindActionCreators, Dispatch} from "redux";

import {borderRadius, flexAbsoluteCenter, pl} from "@pg-design/helpers-css";
import {SearchBoldIcon} from "@pg-design/icons";
import {FormActions, FormFieldType} from "@pg-mono/form";
import {isEqual, noop} from "@pg-mono/nodash";
import {appendQueryString} from "@pg-mono/request";
import {rpAppLink, rpAppPath} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {MobileOptionsModal} from "../../atoms/MobileOptionsModal";
import {createOfferLink} from "../../offer/helpers/create_offer_link";
import {addRegionSlugToQuery} from "../../offer/helpers/friendly_offer_list/add_region_slug_to_query";
import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {DEFAULT_DISTANCE_FOR_PLACES_TAB} from "../../offer/list/constants/offer_list";
import {fetchAllSearchLists, SearchTab} from "../../search/actions/fetch_search_all_action";
import {searchFormActions} from "../../search/actions/search_form_actions";
import {resetActiveDropdownItem} from "../../search/actions/set_active_dropdown_item";
import {DesktopBlend} from "../../search/components/atoms/atoms";
import {ISearchInputValue} from "../../search/components/ISearchInputValue";
import {ISearchAutocompleteFormValues, SearchAutocomplete} from "../../search/components/search_autocomplete/SearchAutocomplete";
import {SearchAutocompleteDropdown} from "../../search/components/search_autocomplete/SearchAutocompleteDropdown";
import {searchFormFields} from "../../search/constants/search_form_constants";
import {getStringQueryValuesFromFormValues} from "../../search/utils/get_string_values_from_form_values";
import {googlePlacesDivId} from "../../search/utils/search_constants";
import {validateQueryValues} from "../../search/utils/validate_query_values";
import {searchSubmitAlgolytics} from "../../tracking/algolytics_hits/search_submit_hit";
import {debouncedSearchTypingHit} from "../../tracking/algolytics_hits/search_typing_hit";
import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {MobileHomepageSearchPlaceholder} from "./MobileHomepageSearchPlaceholder";

interface IStateProps {
    formValues: FormValuesType;
}

interface IActionsProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formActions: FormActions<Record<keyof typeof searchFormFields, any>>;
    resetActiveDropdownItem: typeof resetActiveDropdownItem;
    fetchAllSearchLists: (searchInput: string) => Promise<SearchTab>;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormValuesType = Record<keyof typeof searchFormFields, any>;

interface IOwnProps {
    blendTopPosition: string | number;
    containerHeight: string;
}

interface IProps extends IOwnProps, IStateProps, IActionsProps {}

export const homepageSearchFormFields = {
    search: FormFieldType.Input
};

const HomepageSearchC = (props: IProps) => {
    const [isMobileSearchActive, setMobileSearchActive] = useState(false);
    const [isDesktopSearchBlendActive, setDesktopSearchBlendActive] = useState(false);
    const [windowHeight, setWindowHeight] = useState(0);

    useEffect(() => {
        setWindowHeight(document.body.clientHeight);
    }, [isDesktopSearchBlendActive]);

    const onSubmit = async (formValues: FormValuesType) => {
        searchSubmitAlgolytics({...getTrackedSiteData()});
        const queryValuesRaw = getStringQueryValuesFromFormValues(formValues);
        const searchValue = formValues["search"] as ISearchInputValue;
        let queryValues = validateQueryValues(queryValuesRaw);

        if (searchValue.tabType === SearchTab.Offers) {
            return (window.location.href = createOfferLink({
                ...searchValue.offer,
                vendor: {slug: searchValue.vendor.slug}
            }));
        }
        if (searchValue.tabType === SearchTab.Vendors) {
            return (window.location.href = rpAppLink.vendor.detail.base({
                vendorSlug: searchValue.vendor.slug,
                vendorId: searchValue.vendor.id
            }));
        }

        if (searchValue.tabType === SearchTab.Places) {
            const placeLinkQuery = {
                lat: searchValue.location.lat,
                lng: searchValue.location.lng,
                s: searchValue.place.description,
                distance: DEFAULT_DISTANCE_FOR_PLACES_TAB
            };
            return (window.location.href = appendQueryString(rpAppPath.offer.list, placeLinkQuery));
        }

        if (searchValue.tabType === SearchTab.Regions) {
            queryValues = addRegionSlugToQuery(queryValues, searchValue.regions[0]);
        }

        const {friendlySlug} = offerUrlBuilder(queryValues);
        const url = rpAppLink.offer.listFriendly({friendlySlug});

        return (window.location.href = url);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onAfterChange = (name?: string, value?: any) => {
        const currentValues = name ? {...props.formValues, [name]: value} : props.formValues;
        const queryValues = getStringQueryValuesFromFormValues(currentValues);

        if (isEqual(queryValues, {})) {
            return;
        }

        return onSubmit(currentValues);
    };

    /**
     * Handle filters
     */

    const onChange = (values: Partial<ISearchAutocompleteFormValues>) => {
        if (!!values.search?.label && props.formValues.search.label !== values.search.label) {
            debouncedSearchTypingHit({query: values.search.label});
        }

        props.formActions.update(values);
    };

    const onAfterTabSwitch = () => {
        props.resetActiveDropdownItem();
    };
    /**
     * Render
     */

    const renderMobile = () => {
        return (
            <div css={mobileWrapper}>
                <MobileHomepageSearchPlaceholder onClick={() => setMobileSearchActive(true)} />

                <MobileOptionsModal
                    modalHeading="Wyszukiwanie"
                    isOpen={isMobileSearchActive}
                    onModalClose={() => setMobileSearchActive(false)}
                    css={modalStyle}
                >
                    <SearchAutocomplete
                        values={props.formValues}
                        onChange={onChange}
                        onAfterChange={onAfterChange}
                        onDropdownStatusChange={noop}
                        onAfterTabSwitch={onAfterTabSwitch}
                        fetchAllSearchLists={props.fetchAllSearchLists}
                        autoFocus
                        isInModal
                        inputIcon={<SearchBoldIcon size="2.4" />}
                        renderDropdownList={(props) => (
                            <SearchAutocompleteDropdown
                                dropdownIsOpen={props.dropdownIsOpen}
                                search={props.search}
                                selectedTab={props.selectedTab}
                                onLinkClick={props.onLinkClick}
                                requestStateObj={props.requestStateObj}
                                activeItem={props.activeItem}
                                offers={props.offers}
                                places={props.places}
                                regions={props.regions}
                                removeRegionTag={props.removeRegionTag}
                                vendors={props.vendors}
                            />
                        )}
                    />
                </MobileOptionsModal>
            </div>
        );
    };

    return (
        <>
            <StyledDesktopBlend
                blendTopPosition={props.blendTopPosition}
                height={props.containerHeight}
                isActive={isDesktopSearchBlendActive}
                pageHeight={`${windowHeight}px`}
            />

            <div css={sectionHolder}>
                <div id={googlePlacesDivId} />
                <div css={searchHomePageDesktopWrapperStyle}>
                    <SearchAutocomplete
                        values={props.formValues}
                        onChange={onChange}
                        onAfterChange={onAfterChange}
                        onDropdownStatusChange={setDesktopSearchBlendActive}
                        onAfterTabSwitch={onAfterTabSwitch}
                        fetchAllSearchLists={props.fetchAllSearchLists}
                        autoFocus
                        inputIcon={<SearchBoldIcon size="2.4" />}
                        iconWrapperCss={iconWrapper}
                        inputContainerCss={inputContainerStyle}
                        inputCss={inputCss}
                        regionPillWrapperCss={regionPillWrapper}
                        isHomepage
                        renderDropdownList={(props) => (
                            <SearchAutocompleteDropdown
                                dropdownIsOpen={props.dropdownIsOpen}
                                search={props.search}
                                selectedTab={props.selectedTab}
                                onLinkClick={props.onLinkClick}
                                requestStateObj={props.requestStateObj}
                                activeItem={props.activeItem}
                                offers={props.offers}
                                places={props.places}
                                regions={props.regions}
                                removeRegionTag={props.removeRegionTag}
                                vendors={props.vendors}
                            />
                        )}
                    />
                </div>
                {renderMobile()}
            </div>
        </>
    );
};

export const HomepageSearch = connect(mapStateToProps, mapActionsToProps)(HomepageSearchC);

function mapStateToProps(state: IRPStore): IStateProps {
    return {
        formValues: state.search.formValues
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        formActions: bindActionCreators({...searchFormActions}, dispatch),
        ...bindActionCreators(
            {
                resetActiveDropdownItem,
                fetchAllSearchLists
            },
            dispatch
        )
    };
}

const sectionHolder = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    > div {
        width: 100%;
        align-self: center;
        max-width: 552px;
    }
`;

const searchHomePageDesktopWrapperStyle = (theme: Theme) => css`
    position: relative;
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        padding-top: 0;
        flex-wrap: wrap;
    }
`;

const mobileWrapper = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    max-width: 552px;

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const iconWrapper = (theme: Theme) => css`
    background-color: ${theme.colors.primary};
    ${flexAbsoluteCenter};
    ${borderRadius(0, 0, 0, 2)};
    width: 6.4rem;
    height: 100%;
`;

const inputContainerStyle = (theme: Theme) => css`
    ${borderRadius(0, 0, 2, 2)};
    border: 0;

    @media (min-width: ${theme.breakpoints.md}) {
        ${borderRadius(0, 0, 2, 2)};
    }
`;

const regionPillWrapper = css`
    ${pl(2)};
`;

const inputCss = css`
    ${pl(2)};
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
`;

const StyledDesktopBlend = styled(DesktopBlend)<{blendTopPosition: string | number}>`
    top: ${(props) => props.blendTopPosition};
`;

const modalStyle = (theme: Theme) => css`
    @media (min-width: ${theme.breakpoints.sm}) {
        width: 420px;
    }
`;
