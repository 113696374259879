import * as React from "react";
import {useCallback} from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

export interface IFormProps<TFormValue> extends IFormOwnProps<TFormValue>, IFormHocProps<TFormValue> {}

interface IFormOwnProps<TFormValues> {
    values: TFormValues;
    errors?: Record<keyof TFormValues, string[]>;
    onSubmit?: (e?: React.FormEvent<HTMLElement>) => void;
    onChange: (values: Partial<TFormValues>) => void;
    onAfterChange?: <K extends keyof TFormValues>(fieldName: K, value: TFormValues[K]) => void;
}

export interface IFormHocProps<TFormValues> {
    getFieldProps: <TFieldName extends keyof TFormValues>(fieldName: TFieldName) => IFormFieldProps<TFieldName, TFormValues[TFieldName]>;
}

export interface IFormFieldProps<TName, TValue> {
    name: TName;
    value: TValue;
    error?: string[];
    onChange: (fieldName: string, value: TValue) => void;
    onAfterChange: (fieldName: string, value: TValue) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const standardForm = <TFormValues extends {}, TOwnProps extends IFormProps<TFormValues>>(
    InputComponent: React.ComponentType<TOwnProps>
): React.ComponentType<Omit<TOwnProps, "getFieldProps">> => {
    const Form = (props: IFormOwnProps<TFormValues>) => {
        // form props
        const onSubmitForm = useCallback(
            (e?: React.FormEvent<HTMLElement>) => {
                e && e.preventDefault(); // TODO: provide flag `disablePreventDefaultOnSubmit`
                if (typeof props.onSubmit === "function") {
                    props.onSubmit(e);
                }
            },
            [props.onSubmit]
        );
        // field props
        const onChangeField = useCallback(
            <K extends keyof TFormValues>(fieldName: string, value: TFormValues[K]) => {
                const update: Partial<TFormValues> = {}; // TS hack to properly type {[fieldName]: value}
                update[fieldName as K] = value;
                return props.onChange(update);
            },
            [props.onChange]
        );
        const onAfterChangeField = useCallback(
            <K extends keyof TFormValues>(fieldName: string, value: TFormValues[K]) => {
                if (typeof props.onAfterChange === "function") {
                    props.onAfterChange(fieldName as K, value);
                }
            },
            [props.onAfterChange]
        );
        const getFieldProps = useCallback(
            <TFieldName extends keyof TFormValues>(fieldName: TFieldName): IFormFieldProps<TFieldName, TFormValues[TFieldName]> => {
                return {
                    name: fieldName,
                    value: props.values[fieldName],
                    error: props.errors && props.errors[fieldName],
                    onChange: onChangeField,
                    onAfterChange: onAfterChangeField
                };
            },
            [props.values, props.errors, onChangeField, onAfterChangeField]
        );
        // return <InputComponent {...props} onSubmit={onSubmitForm} getFieldProps={getFieldProps} />;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const inputComponentProps: any = {...props, onSubmit: onSubmitForm, getFieldProps: getFieldProps} as TOwnProps; // TODO: fix that - https://stackoverflow.com/questions/56505560/could-be-instantiated-with-a-different-subtype-of-constraint-object
        return <InputComponent {...inputComponentProps} />;
    };
    return hoistNonReactStatics(Form, InputComponent);
};
