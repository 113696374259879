import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {compact, uniq} from "@pg-mono/nodash";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {PRIVILEGE_OFFER_QUERY_PARAM_NAME} from "../constants/offer_list";
import {IOfferListResponse} from "../types/IOfferListResponse";

const PRIVILEGED_OFFER_LIST = "offer_list/PRIVILEGED_OFFER_LIST";
export const fetchPrivilegedOfferListTypes = createRequestActionTypes(PRIVILEGED_OFFER_LIST);

export const fetchPrivilegedOfferList =
    (ctx: IFetchContext<IRPRequestMeta, {}, Record<string, string | string[] | null>>) =>
    async (dispatch: Dispatch): Promise<IOfferListResponse | null> => {
        const privilegedOfferQueryValue = ctx.route.query[PRIVILEGE_OFFER_QUERY_PARAM_NAME];

        if (privilegedOfferQueryValue) {
            const privilegedOfferListIds =
                typeof privilegedOfferQueryValue === "string"
                    ? [parseInt(privilegedOfferQueryValue, 10)]
                    : uniq(
                          privilegedOfferQueryValue.slice(0, 21).reduce<number[]>((acc, id) => {
                              const value = parseInt(id, 10);

                              if (isFinite(value)) {
                                  acc.push(value);
                              }

                              return acc;
                          }, [])
                      ); // According to business requirements we can have only 21 privileged offers

            const privilegedOfferListUrl = appendQueryString(apiV2ListLink.offer.list(Scenario.OFFER_LIST), {include_offer: privilegedOfferListIds});

            return getRequest(ctx.meta, privilegedOfferListUrl).then((response: IOfferListResponse) => {
                // Sort privileged offers in the same order as in the URL
                const sortedPrivilegedOfferList = privilegedOfferListIds.map((id) => response.results.find((privilegedOffer) => privilegedOffer.id === id));
                // `include_offer` query param (when used with `offer-list` scenario) may return `null` as a result - we need to filter out such values
                const privilegedOfferList = compact(sortedPrivilegedOfferList);

                dispatch({type: fetchPrivilegedOfferListTypes.success, result: {offers: privilegedOfferList}});

                return response;
            });
        }

        return Promise.resolve(null);
    };

export const resetPrivilegedOfferList = () => ({type: fetchPrivilegedOfferListTypes.reset});
